// Login cookie duration
export const EXPIRATION_DURATION = 60 * 60 * 3; // 3 hours
// Profile Types
export const PROFILE_TYPE_PERSONAL = 1;
export const PROFILE_TYPE_PERSONAL_TRAINER = 2;
export const PROFILE_TYPE_COMPANY = 3;
// Default/Fallback Profile Image
export const DEFAULT_PROFILE_IMAGE = "/images/placeholder-image.jpg";
export const DEFAULT_PROFILE_BANNER =
  "https://via.placeholder.com/1200x400.png/000000/FFFFFF/?text=Banner";
// Default/Fallback Advert Images
export const DEFAULT_FULL_WIDTH_BANNER_AD_URL = "/images/placeholder-image.jpg";
// Default/Fallback  article image
export const DEFAULT_ARTICLE_MAIN_URL = "/images/placeholder-image.jpg";
// Default/Fallback image slider
export const DEFAULT_IMAGE_SLIDER_URL = "/images/placeholder-image.jpg";
// Default/Fallback  Subnav image
export const DEFAULT_SUBNAV_IMAGE_URL = "/images/placeholder-image.jpg";
// Default/Fallback  certificate image
export const DEFAULT_CERTIFICATE_IMAGE_URL =
  "https://via.placeholder.com/400x400.png/000000/FFFFFF/?text=Certificate";
// Default/Fallback  product image
export const DEFAULT_PRODUCT_IMAGE_URL =
  "https://via.placeholder.com/400x400.png/000000/FFFFFF/?text=Product";
export const DEFAULT_PRODUCT_IMAGE_WITH_TEXT_URL =
  "https://via.placeholder.com/400x400.png/000000/FFFFFF/?text=";
// Default/Fallback  exercise image
export const DEFAULT_EXERCISE_IMAGE_URL =
  "https://via.placeholder.com/400x400.png/000000/FFFFFF/?text=Exercise";
// Payment methods
export const PAYMENT_IN_PERSON = 1;
export const PAYMENT_ONLINE = 2;
export const PAYMENT_MAP = {
  1: "In-person",
  2: "Online",
};
// Publication Statuses
export const PUBLICATION_STATUS_DRAFT = 1;
export const PUBLICATION_STATUS_LIVE = 2;
export const PUBLICATION_STATUS_ARCHIVED = 3;
export const PUBLICATION_STATUS_PENDING_REVIEW = 4;
export const PUBLICATION_STATUS_INACTIVE = 5;
export const PUBLICATION_STATUS_SCHEDULED = 6;
export const PUBLICATION_STATUS_EXPIRED = 7;
export const PUBLICATION_STATUS_SUSPENDED = 8;
export const PUBLICATION_MAP = {
  1: "Draft",
  2: "Live",
  3: "Archived",
  4: "Pending Review",
  5: "Inactive",
  6: "Scheduled",
  7: "Expired",
  8: "Suspended",
};
// Verified creditial
export const VERIFIED_STATUS_INITIAL = 1;
export const VERIFIED_STATUS_PENDING = 2;
export const VERIFIED_STATUS_APPROVED = 3;
export const VERIFIED_STATUS_REJECTED = 4;
// Dispute Statuses
export const DISPUTE_OPEN = 1;
export const DISPUTE_UNDER_NEGOTIATION = 2;
export const DISPUTE_AGREED_RESOLUTION = 3;
export const DISPUTE_WITHDRAWN = 4;
export const DISPUTE_CLOSED_NO_RESOLUTION = 5;
// Company verification sections
export const SECTION_COMPANY_TYPE = 1;
export const SECTION_COMPANY_BASIC = 2;
export const SECTION_COMPANY_LOCATION = 3;
export const SECTION_COMPANY_CALENDAR = 4;
export const SECTION_COMPANY_BREAKS = 5;
export const SECTION_COMPANY_PRICES = 6;
export const SECTION_COMPANY_IMAGES = 7;
export const SECTION_FINISHED_COMPANY_ADDED = 8;
// Calendar Entry Booking Statuses
export const PENDING_BOOKING_CONFIRMATION = 1; // Booking is awaiting confirmation
export const BOOKING_SCHEDULED = 2; // Booking has been scheduled
export const BOOKING_AWAITING_REVIEW = 3; // Booking is awaiting review
export const BOOKING_PAYMENT_AUTHORIZED = 4; // Payment for the booking is authorized
export const BOOKING_PAYMENT_DISPUTED = 5; // Payment for the booking is disputed
export const BOOKING_CANCELLED = 6; // Booking has been cancelled
export const BOOKING_REJECTED = 7; // Booking has been rejected
/* export const BOOKING_STATUS_MAP = {
  1: "Pending",
  2: "Scheduled",
  3: "Awaiting Review",
  4: "Payment Authorized",
  5: "Payment Disputed",
  6: "Cancelled",
  7: "Rejected",
}; */
// Dates and times standardization
export const DATE_FORMATS = {
  standard: "yyyy-MM-dd", // Example: "2024-08-26"
  dayMonthYear: "EEEE, do MMM yyyy", // Example: "Wednesday, 28th Aug 2024"
  isoWithMillis: "yyyy-MM-dd'T'HH:mm:ss.SSSX", // Example: "2024-08-26T14:35:20.123Z"
  dateTime: "yyyy-MM-dd HH:mm:ss", // Example: "2024-08-26 14:35:20"
  isoWithoutMillis: "yyyy-MM-dd'T'HH:mm:ssxxx", // Example: "2024-08-26T14:35:20-05:00"
  hourMinute: "HH:mm", // Example: "15:45"
  timeStampFull: "yyyy-MM-dd kk:mm:ss X", // Example: "2024-08-26 14:35:20 +00"
  isoWithMillisAndOffset: "yyyy-MM-dd'T'HH:mm:ss.SSSXXX", // Example: "2024-08-26T14:35:20.123+05:00"
  dayMonthYearShort: "do LLL yy", // Example: "26th Aug 24"
  dayTimeDate: "EE p - dd/MM/yy", // Example: "Mon 3:45 PM - 26/08/24"
  timeDateSimple: "kk:mm yyyy-MM-dd", // Example: "14:35 2024-08-26"
  dayOnly: "d", // Example: "26"
  monthAbbreviated: "LLL", // Example: "Aug"
  dayOfWeekAbbreviated: "ccc", // Example: "Mon"
  hourMinute24: "kk:mm", // Example: "14:35"
  fullDateTimeCustom: "K:mmbbb EEEE do MMMM y", // Example: "1:45pm Monday 26th August 2024"
  dayWithOrdinal: "do", // Example: "26th"
  monthYearShort: "MMM yy", // Example: "Aug 24"
  timeWithAmPm: "HH:mm aa", // Example: "02:30 PM"
  timeWithDayMonthYear: "H:mm do MMMM, yyyy", // Example: "9:30 26th August, 2024"
  abbreviatedDayWithDate: "E d", // Example: "Mon 26"
  fullMonth: "MMMM", // Example: "August"
  fullYear: "yyyy", // Example: "2024"
  monthDayYearShort: "MM-dd-yy", // Example: "08-26-24"
  fullMonthDayYear: "MMMM do yyyy", // Example: "August 26th 2024"
  fullDayOfWeek: "EEEE", // Example: "Monday"
  customerCreatedAt: "HH:mm E M/d/yyyy", // Example: "13:02 Mon 8/6/2024"
};
